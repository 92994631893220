// Api Slice
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl:
    process.env.REACT_APP_DEV === "development"
      ? "https://manager-edge-d.adquire.com/apiv1/"
      : "https://manager-edge.adquire.com/apiv1/", // To move somewhere else.
  prepareHeaders: (headers, { getState }) => {
    // Get auth token from store.
    const token = getState().auth.user.id_token;

    if (token) {
      headers.set("authorization", token); // Set authorization in header.
    }

    return headers;
  },
});

// const baseQueryWithReauth = async (args, api, options) => {
//   let result = await baseQuery(args, api, options);
//   if (result.error && result.error.status === 401) {
//     // try to get a new token
//     api.dispatch(refreshToken());
//     if (api.getState().auth.session.id_token) {
//       // retry the initial query
//       result = await baseQuery(args, api, options);
//     } else {
//       api.dispatch(logout());
//     }
//   }
//   return result;
// };

export const adquireApi = createApi({
  reducerPath: "adquireApi",
  baseQuery: baseQuery,

  // List of tagTypes available for cache.
  tagTypes: [
    "Campaign",
    "Placement",
    "Configuration",
    "Category",
    "Criteria",
    "Question",
    "Creative",
    "CampaignGroup",
    "PlacementGroup",
    "Association",
    "BadWords",
    "BlockedEmailDomains",
    "DomainsToReview",
    "BlockedEmails",
    "BlockedAreaCodes",
    "Customer",
    "Validator",
    "CustomValidation",
    "LeadDelivery",
    "ContactNotification",
    "DarwinGroup",
    "DarwinGroupMember",
    "Deliverable",
    "Reviewable",
  ],

  // Endpoint definitions:
  endpoints: (builder) => ({
    // Returns all campaingns. Accepts limit and offset in options object
    getCampaigns: builder.query({
      query: (
        options = {
          thin: "yes",
          includeArchived: "no",
          includeLegacy: "no",
        }
      ) => ({
        url: `/campaigns/campaigns/all/?thin=${options.thin}&include_archived=${options.includeArchived}&include_legacy_archived=${options.includeLegacy}`,
      }),

      transformResponse: (response) => {
        return response.records.map((campaign) => {
          let { rates, isArchived, isPaused, startDate, endDate } = campaign;
          let rate = rates.length ? rates[0].rate : 0; // Get campaign rate. (will change)
          let currentTimestamp = parseInt(Date.now() / 1000);
          let inactive =
            isPaused ||
            isArchived ||
            (parseInt(startDate) > 0 &&
              parseInt(endDate) !== 0 &&
              parseInt(endDate) < currentTimestamp);

          return { isActive: !inactive, rate, ...campaign };
        });
      },

      // Provides tags for cached campaign data.
      // providesTags: (result, error, arg) => {
      //   return result
      //     ? [
      //         ...result.map(({ UUID }) => ({ type: "Campaign", id: UUID })),
      //         "Campaign",
      //       ]
      //     : ["Campaign"];
      // },
    }),

    getCampaignById: builder.query({
      query: (options) => ({
        url: `/campaigns/campaigns/${options.UUID}`,
      }),

      transformResponse: (response) => {
        let { rates, isArchived, isPaused, startDate, endDate } = response;
        let rate = rates.length ? rates[0].rate : 0; // Get campaign rate. (will change)
        let currentTimestamp = parseInt(Date.now() / 1000);

        let inactive =
          isPaused ||
          isArchived ||
          (parseInt(startDate) > 0 &&
            parseInt(endDate) !== 0 &&
            parseInt(endDate) < currentTimestamp);

        return { isActive: !inactive, rate, ...response };
      },

      providesTags: (result, error, arg) => {
        return result ? [{ type: "Campaign", id: result.UUID }] : ["Campaign"];
      },
    }),

    updateCampaignById: builder.mutation({
      query: (options = { isNew: false, data: {} }) => ({
        url: `/campaigns/campaigns/${options.isNew ? "" : options.data.UUID}`,
        method: options.isNew ? "POST" : "PUT",
        body: options.data,
      }),

      // Invalidates cache for the specified campaign for automatic updates.
      invalidatesTags: (result, error, arg) => {
        return [{ type: "Campaign", id: arg.UUID }];
      },
    }),

    searchCampaigns: builder.mutation({
      query: (body) => {
        return {
          url: `/campaigns/search/`,
          method: "POST",
          body,
        };
      },
    }),

    getCustomers: builder.query({
      query: (options = { limit: 0, offset: 0 }) => ({
        url: `customers/customers/?limit=${options.limit}&offset=${options.offset}`,
      }),
      transformResponse: (response) =>
        response.records.map((customer) => {
          let {
            UUID: value,
            name: text,
            description,
            contacts,
            type,
          } = customer; // Renamed for ease of use in <select>.
          return { value, text, description, type, contacts };
        }),

      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.map(({ value }) => ({ type: "Customer", id: value })),
              "Customer",
            ]
          : ["Customer"];
      },
    }),

    updateCustomers: builder.mutation({
      query: (body) => ({
        url: `customers/customers/${
          body.hasOwnProperty("UUID") ? body.UUID : ""
        }`,
        method: body.UUID ? "PUT" : "POST",
        body,
      }),

      invalidatesTags: (result, error, arg) => {
        return arg.hasOwnProperty("UUID")
          ? [{ type: "Customer", id: arg.UUID }]
          : ["Customer"];
      },
    }),

    addKeyCloakAccount: builder.mutation({
      query: ({ UUID, placementUUID, contact }) => ({
        url: `customers/create_account/${UUID}/${placementUUID}`,
        method: "POST",
        body: contact,
      }),

      invalidatesTags: (result, error, arg) => [
        { type: "Customer", id: arg.UUID },
      ],
    }),

    getContactNotificationConfigurations: builder.query({
      query: (options) => ({ url: `notification/configuration` }),
      transformResponse: (response) => response.records,
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.map(({ id }) => {
                return {
                  type: "ContactNotification",
                  id: id,
                };
              }),
              "ContactNotification",
            ]
          : ["ContactNotification"];
      },
    }),

    saveContactNotificationConfiguration: builder.mutation({
      query: (body) => ({
        url: `notification/configuration`,
        method: "POST",
        body,
      }),

      invalidatesTags: (result, error, arg) => {
        return arg.hasOwnProperty("id")
          ? [{ type: "ContactNotification", id: arg.id }]
          : ["ContactNotification"];
      },
    }),

    deleteContactNotificationConfiguration: builder.mutation({
      query: (body) => ({
        url: `notification/configuration/${body.id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => {
        return arg.hasOwnProperty("id")
          ? [{ type: "ContactNotification", id: arg.id }]
          : ["ContactNotification"];
      },
    }),

    getSalesPerson: builder.query({
      query: (options = { limit: 50, offset: 0, type: 3 }) => ({
        url: `customers/customers/?limit=${options.limit}&offset=${options.offset}&type=${options.type}`,
      }),
      transformResponse: (response) =>
        response.records.map((salesPerson) => {
          let { UUID: value, name: text } = salesPerson;
          return { value, text };
        }),
    }),

    getDefaultValidators: builder.query({
      query: (options) => ({
        url: `campaigns/default-validators`,
      }),

      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.validators.map((v) => ({
                type: "Validator",
                id: v.service_name,
              })),
              "Validator",
            ]
          : ["Validator"];
      },
    }),

    getCampaignValidationData: builder.mutation({
      query: (body) => ({
        url: `campaigns/validation-data`,
        method: "POST",
        body: { records: [] },
      }),
    }),

    getCampaignCustomValidation: builder.query({
      query: (options) => ({
        url: `custom-validation/configuration/${options.UUID}`,
      }),
      providesTags: ["CustomValidation"],
    }),

    testCustomValidation: builder.mutation({
      query: (body) => ({
        url: `custom-validation/test`,
        method: "POST",
        body,
      }),
    }),

    saveCustomValidation: builder.mutation({
      query: (body) => ({
        url: `custom-validation/configuration/${body.UUID}`,
        method: "POST",
        body,
      }),

      invalidatesTags: ["CustomValidation"],
    }),

    getCampaignLeadDelivery: builder.query({
      query: (options) => ({
        url: `deliverable-manager/configurations/?limit=500&offset=0&campaign=${options.UUID}`,
      }),
      transformResponse: (response) => response.records,
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.map((l) => ({ type: "LeadDelivery", id: l.UUID })),
              "LeadDelivery",
            ]
          : ["LeadDelivery"];
      },
    }),

    saveCampaignLeadDelivery: builder.mutation({
      query: (body) => ({
        url: `deliverable-manager/configurations/${body.UUID}`,
        method: "POST",
        body,
      }),

      invalidatesTags: (result, error, arg) => {
        return arg.hasOwnProperty("UUID")
          ? [{ type: "LeadDelivery", id: arg.UUID }]
          : ["LeadDelivery"];
      },
    }),

    getLatestDeliveries: builder.query({
      query: (body) => ({
        url: `deliverable-manager/deliveries/latest/${body.UUID}?limit=${body.limit}&offset=0`,
      }),
      transformResponse: (response) => response.records,
    }),

    createDeliveryFromQuery: builder.mutation({
      query: (body) => ({
        url: `deliverable-manager/deliveries/create-from-query/`,
        mutation: "POST",
        body,
      }),
    }),

    deleteLeadDeliveryConfig: builder.mutation({
      query: (body) => ({
        url: `deliverable-manager/configurations/${body.UUID}`,
        method: "DELETE",
      }),

      invalidatesTags: (result, error, arg) => {
        return arg.hasOwnProperty("UUID")
          ? [{ type: "LeadDelivery", id: arg.UUID }]
          : ["LeadDelivery"];
      },
    }),

    cloneLeadDeliveryConfig: builder.mutation({
      query: (body) => ({
        url: `deliverable-manager/clone-configuration/${body.UUID}`,
        method: "POST",
      }),

      invalidatesTags: (result, error, arg) => {
        return arg.hasOwnProperty("UUID")
          ? [{ type: "LeadDelivery", id: arg.UUID }]
          : ["LeadDelivery"];
      },
    }),

    testDelivery: builder.mutation({
      query: (body) => ({
        url: `deliverable-manager/test/?verbose=${body.verbose}`,
        method: "POST",
        body: body.data,
      }),
    }),

    getCustomFields: builder.query({
      query: (body) => ({
        url: `deliverable-manager/available-custom-fields/${body.UUID}`,
      }),
      transformResponse: (response) => response.records,
    }),

    addDefaultValidator: builder.mutation({
      query: ({ name }) => ({
        url: `campaigns/default-validators/${name}`,
        method: "PUT",
      }),

      invalidatesTags: (result, error, arg) => {
        return arg.hasOwnProperty("UUID")
          ? [{ type: "Validator", id: arg.name }]
          : ["Validator"];
      },
    }),

    removeDefaultValidator: builder.mutation({
      query: ({ name }) => ({
        url: `campaigns/default-validators/${name}`,
        method: "DELETE",
      }),

      invalidatesTags: (result, error, arg) => {
        return arg.hasOwnProperty("UUID")
          ? [{ type: "Validator", id: arg.name }]
          : ["Validator"];
      },
    }),

    disableValidator: builder.mutation({
      query: ({ name }) => ({
        url: `campaigns/disable-validator/${name}`,
        method: "PUT",
      }),
    }),

    getValidators: builder.query({
      query: (options = { verbose: true }) => ({
        url: `validation-coordinator/validators/?verbose=${options.verbose}`,
      }),
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.map((v) => ({ type: "Validator", id: v.service_name })),
              "Validator",
            ]
          : ["Validator"];
      },
    }),

    getEmailValidatorConfiguration: builder.query({
      async queryFn(arg, queryApi, options, fetchWithBQ) {
        const campaignEmailConfiguration = await fetchWithBQ(
          `email-validation/configuration/${arg.UUID}`
        );

        if (
          campaignEmailConfiguration.error &&
          campaignEmailConfiguration.error.status === 404
        ) {
          return {
            data: {
              campaignUUID: arg.UUID,
              briteVerify: false,
              emailOversight: true,
              strictEmailOversight: false,
              towerData: false,
            },
          };
        }

        if (
          campaignEmailConfiguration.error &&
          campaignEmailConfiguration.error.status !== 404
        ) {
          throw campaignEmailConfiguration.error;
        }

        return { data: campaignEmailConfiguration.data };
      },

      // Provides tags for cached configuration data.
      providesTags: [{ type: "Configuration", id: "email" }, "Configuration"],
    }),

    updateEmailValidatorConfiguration: builder.mutation({
      query: (body) => ({
        url: `email-validation/configuration/${body.campaignUUID}`,
        method: "POST",
        body,
      }),

      // Invalidates cache for the specified configuration for automatic updates.
      invalidatesTags: [{ type: "Configuration", id: "email" }],
    }),

    getDefaultPhoneConfiguration: builder.query({
      query: (options) => ({
        url: "phone-verification/default-configuration",
      }),
    }),

    getPhoneValidatorConfiguration: builder.query({
      async queryFn(arg, queryApi, options, fetchWithBQ) {
        // Check if campaign has a phone validator configuration.
        const campaignPhoneConfiguration = await fetchWithBQ(
          `phone-verification/campaign-configurations/${arg.UUID}`
        );

        // If no configuration found:
        if (!campaignPhoneConfiguration.data) {
          // Get default configuration.
          const defaultPhoneConfiguration = await fetchWithBQ(
            "phone-verification/default-configuration"
          );

          // Return default configuration or error.
          return defaultPhoneConfiguration.data
            ? { data: { config: defaultPhoneConfiguration.data } }
            : { error: defaultPhoneConfiguration.error };
        }

        if (
          campaignPhoneConfiguration.error &&
          campaignPhoneConfiguration.error.status !== 404
        ) {
          throw campaignPhoneConfiguration.error;
        }

        return { data: campaignPhoneConfiguration.data };
      },

      // Provides tags for cached configuration data.
      providesTags: [{ type: "Configuration", id: "phone" }, "Configuration"],
    }),

    updatePhoneValidatorConfiguration: builder.mutation({
      query: (body) => ({
        url: `phone-verification/campaign-configurations/${body.campaignUUID}`,
        method: "POST",
        body,
      }),

      // Invalidates cache for the specified configuration for automatic updates.
      invalidatesTags: [{ type: "Configuration", id: "phone" }],
    }),

    getDefaultAddressConfiguration: builder.query({
      query: (options) => ({
        url: "address-validation/default-configuration",
      }),
    }),

    getAddressValidatorConfiguration: builder.query({
      async queryFn(arg, queryApi, options, fetchWithBQ) {
        // Check if campaign has an address validator configuration.
        const campaignAddressConfiguration = await fetchWithBQ(
          `address-validation/campaign-configurations/${arg.UUID}`
        );

        // If no configuration found:
        if (!campaignAddressConfiguration.data) {
          // Get default configuration.
          const defaultAddressConfiguration = await fetchWithBQ(
            "address-validation/default-configuration/"
          );

          // Return default configuration or error.
          return defaultAddressConfiguration.data
            ? { data: defaultAddressConfiguration.data }
            : { error: defaultAddressConfiguration.error };
        }

        if (
          campaignAddressConfiguration.error &&
          campaignAddressConfiguration.error.status !== 404
        ) {
          throw campaignAddressConfiguration.error;
        }

        return { data: campaignAddressConfiguration.data.config };
      },

      // Provides tags for cached configuration data.
      providesTags: [{ type: "Configuration", id: "address" }, "Configuration"],
    }),

    updateAddressValidatorConfiguration: builder.mutation({
      query: (body) => ({
        url: `address-validation/campaign-configurations/${body.UUID}`,
        method: "POST",
        body,
      }),

      // Invalidates cache for the specified configuration for automatic updates.
      invalidatesTags: [{ type: "Configuration", id: "address" }],
    }),

    getCustomValidatorConfiguration: builder.query({
      query: (options) => ({
        url: `custom-validation/configuration/${options.UUID}`,
      }),
    }),

    getAllCategories: builder.query({
      query: (options) => ({
        url: `tagging/tags`,
      }),
      transformResponse: (response) => response.records,
    }),

    getCategoriesById: builder.query({
      query: (options) => ({
        url: `tagging/association/${options.UUID}`,
        // url: `tagging/tags`,
      }),
      transformResponse: (response) => response.records,
      // Provides tags for cached campaign category data.
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ UUID }) => ({ type: "Category", id: UUID })),
              "Category",
            ]
          : ["Category"],
    }),

    updateCategoriesById: builder.mutation({
      query: (body) => ({
        url: `tagging/association`,
        method: "PUT",
        body,
      }),
      // Invalidates cache for the current campaign categories for automatic updates.
      invalidatesTags: (result, error, arg) => [
        { type: "Category", id: arg.UUID },
      ],
    }),

    getCampaignCriteria: builder.query({
      query: (options) => ({
        url: `criteria-filtering/criteria/${options.UUID}`,
      }),
      transformResponse: (response) => {
        return response.criteriaFilter;
      },
      providesTags: (result) =>
        result
          ? [{ type: "Criteria", id: result.campaignUuid }, "Criteria"]
          : ["Criteria"],
    }),

    updateCampaignCriteria: builder.mutation({
      query: (body) => ({
        url: `criteria-filtering/criteria/${body.campaignUuid}`,
        method: "POST",
        body,
      }),

      invalidatesTags: (result, error, arg) => {
        return [{ type: "Criteria", id: arg.campaignUuid }];
      },
    }),

    getStats: builder.query({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        let endDate = _arg.hasOwnProperty("endDate")
          ? new Date(_arg.endDate)
          : new Date();

        let startDate = _arg.hasOwnProperty("startDate")
          ? new Date(_arg.startDate)
          : new Date(
              endDate.getFullYear(),
              endDate.getMonth(),
              endDate.getDate() - _arg.daysAgo
            );

        const optinQuery = await fetchWithBQ(
          `optin-count/counts/${_arg.campaignUUID}/?placement=${
            _arg.placementUUID
          }&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
        );

        const leadQuery = await fetchWithBQ(
          `lead-count/counts/${_arg.campaignUUID}/?placement=${
            _arg.placementUUID
          }&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
        );

        // if (optinQuery.error) return { error: optinQuery.error };
        // if (leadQuery.error) return { error: leadQuery.error };
        return {
          data: { optins: optinQuery.data.count, leads: leadQuery.data.count },
        };
      },
    }),

    getCampaignAssociations: builder.query({
      query: (options) => ({
        url: `placements/campaign-associations/${options.UUID}?type=all`,
      }),
      transformResponse: (response) => {
        return response.associations;
      },
      providesTags: (result) => {
        return result
          ? [
              ...result.map(({ campaignUUID }) => ({
                type: "Association",
                id: campaignUUID,
              })),
              "Association",
            ]
          : ["Association"];
      },
    }),

    updateCampaignAssociations: builder.mutation({
      query: (body) => ({
        url: `placements/campaign-associations/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Association"],
    }),

    getCampaignQuestions: builder.query({
      query: (options) => ({
        url: `questions/questions/${options.UUID}/?byCampaign=true`,
      }),
      transformResponse: (response) => {
        return response.records;
      },
      // Assign tags to response for automated refetching:
      providesTags: (result, error) =>
        result
          ? [
              ...result.map(({ UUID }) => {
                return { type: "Question", id: UUID }; //
              }),
              "Question",
            ]
          : ["Question"],
    }),

    addCampaignQuestion: builder.mutation({
      query: (body) => ({
        url: `questions/questions/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Question"],
    }),

    updateCampaignQuestion: builder.mutation({
      query: (body) => ({
        url: `questions/questions/${body.UUID}`,
        method: "PUT",
        body: body.question,
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: "Question", id: arg.UUID }]; // Trigger auto refetch by invalidating tag of updated question.
      },
    }),

    removeCampaignQuestion: builder.mutation({
      query: (body) => ({
        url: `questions/questions/${body.UUID}`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Question", id: arg.UUID },
      ],
    }),

    reorderCampaignQuestions: builder.mutation({
      query: (body) => ({
        url: `questions/reorder-questions/${body.campaignUuid}`,
        method: "PUT",
        body: { records: body.records },
      }),
      invalidatesTags: ["Question"],
    }),

    testPrePing: builder.mutation({
      query: (body) => ({
        url: `preping/pre-ping/?test=1`,
        method: "POST",
        body,
      }),
      // transformResponse: (response) => response.message,
    }),

    previewPrePing: builder.mutation({
      query: (body) => ({
        url: `preping/preview`,
        method: "POST",
        body,
      }),
      // transformResponse: (response) => response.message,
    }),

    getCampaignCreative: builder.query({
      query: (options) => ({
        url: `creatives/creatives/${options.UUID}`,
      }),

      providesTags: (result) =>
        result
          ? [{ type: "Creative", id: result.uuid }, "Creative"]
          : ["Creative"],
    }),

    getCampaignCreativePreview: builder.mutation({
      query: (body) => ({
        url: `creatives/testcreative/`,
        method: "POST",
        body,
      }),
    }),

    updateCreative: builder.mutation({
      query: (body) => ({
        url: `creatives/creatives/${body.uuid}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: "Creative", id: arg.uuid }];
      },
    }),

    newCreative: builder.mutation({
      query: (body) => ({
        url: `creatives/creatives/`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        return ["Creative"];
      },
    }),

    removeCreative: builder.mutation({
      query: (options) => ({
        url: `creatives/creatives/${options.UUID}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: "Creative", id: arg.UUID }];
      },
    }),

    getCreativeCriteria: builder.query({
      query: (options) => {
        return {
          url: `criteria-filtering/creative-criteria/?creative_uuid=${options.UUID}`,
        };
      },

      transformResponse: (response) => {
        return response.criteriaFilter;
      },

      providesTags: (result) => {
        return result
          ? [{ type: "Criteria", id: result.creativeUuid }, "Criteria"]
          : ["Criteria"];
      },
    }),

    updateCreativeCriteria: builder.mutation({
      query: (body) => {
        return {
          url: `criteria-filtering/creative-criteria/?creative_uuid=${body.creative_uuid}`,
          method: "POST",
          body: body,
        };
      },

      invalidatesTags: (result, error, arg) => {
        return arg.creative_uuid
          ? [{ type: "Criteria", id: arg.creative_uuid }]
          : ["Criteria"];
      },
    }),

    getCampaignGroups: builder.query({
      query: (options) => ({
        url: `campaigns/campaign-groups/${options.UUID}?limit=500&offset=0`,
      }),
      providesTags: (result) => {
        return result
          ? [{ type: "CampaignGroup", id: result.uuid }, "CampaignGroup"]
          : ["CampaignGroup"];
      },
    }),

    updateCampaignGrouping: builder.mutation({
      query: (body) => ({
        url: `campaigns/campaign-groups/`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: "CampaignGroup", id: arg.uuid }];
      },
    }),

    removeCampaignFromGroup: builder.mutation({
      query: ({ UUID }) => ({
        url: `campaigns/campaign-groups/${UUID}`,
        method: "DELETE",
        body: { UUID: UUID },
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: "CampaignGroup", id: arg.groupUUID }];
      },
    }),

    getPlacementGroups: builder.query({
      query: (options) => ({
        url: `placements/placement-groups/${options.UUID}?limit=500&offset=0`,
      }),
      providesTags: (result) => {
        return result
          ? [{ type: "PlacementGroup", id: result.uuid }, "PlacementGroup"]
          : ["PlacementGroup"];
      },
    }),

    updatePlacementGrouping: builder.mutation({
      query: (body) => ({
        url: `placements/placement-groups/`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: "PlacementGroup", id: arg.uuid }];
      },
    }),

    removePlacementFromGroup: builder.mutation({
      query: ({ UUID }) => ({
        url: `placements/placement-groups/${UUID}`,
        method: "DELETE",
        body: { UUID: UUID },
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: "PlacementGroup", id: arg.groupUUID }];
      },
    }),

    getPlacements: builder.query({
      query: (
        options = {
          thin: "yes",
          includeArchived: "no",
          includeLegacy: "no",
        }
      ) => ({
        url: `placements/placements/?thin=${options.thin}&include_archived=${options.includeArchived}&include_legacy_archived=${options.includeLegacy}`,
      }),
      transformResponse: (response) => response.records,
      providesTags: (result) => {
        return result
          ? [
              ...result.map(({ UUID }) => ({ type: "Placement", id: UUID })),
              "Placement",
            ]
          : ["Placement"];
      },
    }),

    getPlacementById: builder.query({
      query: (options) => ({
        url: `placements/placements/${options.UUID}/?thin=no`,
      }),
      providesTags: (result, error, arg) => {
        return result
          ? [{ type: "Placement", id: result.UUID }]
          : ["Placement"];
      },
    }),

    updatePlacementById: builder.mutation({
      query: (body) => {
        return {
          url: `placements/placements/${body.UUID}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, arg) => {
        return [{ type: "Placement", id: arg.UUID }];
      },
    }),

    newPlacement: builder.mutation({
      query: (body) => {
        return {
          url: `placements/placements/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, arg) => {
        return ["Placement"];
      },
    }),

    getRankers: builder.query({
      query: (options) => ({
        url: `campaign-sorting/rankers/${options.UUID}`,
      }),
    }),

    getLeadAcceptance: builder.query({
      query: (options) => ({
        url: `campaign-sorting/associated-lead-acceptance?placement_uuid=${options.UUID}`,
      }),
      transformResponse: (response) => response.records,
    }),

    updateLeadAcceptance: builder.mutation({
      query: (body) => {
        return {
          url: `campaign-sorting/batch-placement-associated-configurations?placement_uuid=${body.UUID}`,
          method: "POST",
          body: body.data,
        };
      },
    }),

    getPlacementAddressValidation: builder.query({
      query: (options) => {
        return {
          url: `address-validation/batch-placement-associated-configurations?placement_uuid=${options.UUID}`,
        };
      },
      transformResponse: (response) => response.records,
    }),

    updatePlacementAddressValidation: builder.mutation({
      query: (body) => {
        return {
          url: `address-validation/batch-placement-associated-configurations?placement_uuid=${body.UUID}`,
          method: "POST",
          body: body.data,
        };
      },
    }),

    getPlacementCriteria: builder.query({
      query: (options) => {
        return {
          url: `criteria-filtering/associated-criteria?placement_uuid=${options.UUID}`,
        };
      },

      transformResponse: (response) => {
        return response.records;
      },

      providesTags: (result) => {
        return result.length
          ? [{ type: "Criteria", id: result[0].campaignUuid }, "Criteria"]
          : ["Criteria"];
      },
    }),

    updatePlacementCriteria: builder.mutation({
      query: (body) => {
        return {
          url: `criteria-filtering/associated-criteria/?placement_uuid=${body.UUID}`,
          method: "POST",
          body: body.data,
        };
      },

      invalidatesTags: (result, error, arg) => {
        return arg.campaignUUID
          ? [{ type: "Criteria", id: arg.campaignUUID }]
          : ["Criteria"];
      },
    }),

    removePlacementCriteria: builder.mutation({
      query: (body) => {
        return {
          url: `criteria-filtering/associated-criteria`,
          method: "DELETE",
          body: body.data,
        };
      },

      invalidatesTags: (result, error, arg) => {
        return [{ type: "Criteria", id: arg.campaignUUID }];
      },
    }),

    uploadEmailSuppression: builder.mutation({
      query: (body) => {
        return {
          url: `lead-deduplication/bulk-hash-leads`,
          method: "POST",
          body,
        };
      },
    }),

    searchZipCodes: builder.mutation({
      query: (body) => {
        return {
          url: `zip-in-radius/zips`,
          method: "POST",
          body,
        };
      },
    }),

    getBadWords: builder.query({
      query: (options) => {
        return {
          url: `bad-words/bad-words`,
        };
      },

      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.FullMatch.map((word) => ({
                type: "BadWords",
                id: word,
              })),
              ...result.PartialMatch.map((word) => ({
                type: "BadWords",
                id: word,
              })),
              "BadWords",
            ]
          : ["BadWords"];
      },
    }),

    addBadWord: builder.mutation({
      query: (body) => {
        return { url: `bad-words/bad-words`, method: "POST", body };
      },
      invalidatesTags: ["BadWords"],
    }),

    removeBadWord: builder.mutation({
      query: ({ word }) => {
        return {
          url: `bad-words/bad-words/${encodeURIComponent(word)}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, arg) => {
        return [{ type: "BadWords", id: arg.word }];
      },
    }),

    testBadWord: builder.query({
      query: ({ word }) => {
        return { url: `bad-words/test-word/?word=${encodeURIComponent(word)}` };
      },
    }),

    getIpLookup: builder.mutation({
      query: ({ ip }) => {
        return {
          url: `ip-lookup/lookup/`,
          method: "POST",
          body: { ip: ip },
        };
      },
    }),

    getAllBlockedEmailDomains: builder.query({
      query: (options) => {
        return {
          url: `blocked-email-domains/domains/?limit=${options.limit}&offset=${options.offset}`,
        };
      },
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.Domains.map((d) => ({
                type: "BlockedEmailDomains",
                id: d.name,
              })),

              "BlockedEmailDomains",
            ]
          : ["BlockedEmailDomains"];
      },
    }),

    getBlockedEmails: builder.query({
      query: (options) => {
        return {
          url: `email-blacklist/emails/?limit=${options.limit}&offset=${options.offset}`,
        };
      },
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.emails.map((e) => ({
                type: "BlockedEmails",
                id: e.ID,
              })),

              "BlockedEmails",
            ]
          : ["BlockedEmails"];
      },
    }),

    getEmailDomainsToReview: builder.query({
      query: (options) => {
        return {
          url: `blocked-email-domains/domain-hits/?limit=${options.limit}&offset=${options.offset}&count=4&reviewed=false`,
        };
      },

      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.records.map((d) => ({
                type: "DomainsToReview",
                id: d.name,
              })),

              "DomainsToReview",
            ]
          : ["DomainsToReview"];
      },
    }),

    setEmailDomainAsReviewed: builder.mutation({
      query: (body) => {
        return {
          url: `blocked-email-domains/domain-hits/`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, arg) => {
        return [{ type: "DomainsToReview", id: arg.name }];
      },
    }),

    updateBlockedEmailDomain: builder.mutation({
      query: (body) => {
        return {
          url: `blocked-email-domains/domains/${encodeURIComponent(body.name)}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, arg) => {
        return [{ type: "BlockedEmailDomains", id: arg.name }];
      },
    }),

    updateBulkBlockedEmailDomain: builder.mutation({
      query: (body) => {
        return {
          url: `blocked-email-domains/batchUpdate/?replace=${body.replace}`,
          method: "POST",
          body: body.data,
        };
      },
      invalidatesTags: ["BlockedEmailDomains"],
    }),

    removeBlockedEmailDomain: builder.mutation({
      query: (body) => {
        return {
          url: `blocked-email-domains/domains/${encodeURIComponent(body.name)}`,
          method: "DELETE",
        };
      },

      invalidatesTags: (result, error, arg) => {
        return [{ type: "BlockedEmailDomains", id: arg.name }];
      },
    }),

    updateBulkBlockedEmails: builder.mutation({
      query: (body) => {
        return {
          url: `email-blacklist/batchUpdate/?replace=${body.replace}}`,
          method: "POST",
          body: body.data,
        };
      },

      invalidatesTags: ["BlockedEmails"],
    }),

    validateBlockedEmails: builder.mutation({
      query: (body) => {
        return {
          url: `email-blacklist/validate`,
          method: "POST",
          body,
        };
      },
    }),

    removeBlockedEmail: builder.mutation({
      query: (body) => {
        return {
          url: `email-blacklist/emails/${encodeURIComponent(body.email)}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["BlockedEmails"],
    }),

    getBlockedAreaCodes: builder.query({
      query: (options) => {
        return {
          url: `areacode-blacklist/areacodes/?limit=${options.limit}&offset=${options.offset}`,
        };
      },
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.AreaCodes.map((e) => ({
                type: "BlockedAreaCodes",
                id: e.areacode,
              })),

              "BlockedAreaCodes",
            ]
          : ["BlockedAreaCodes"];
      },
    }),

    updateBulkBlockedAreaCodes: builder.mutation({
      query: (body) => {
        return {
          url: `areacode-blacklist/batchUpdate?replace=${body.replace}`,
          method: "POST",
          body: body.data,
        };
      },
      invalidatesTags: ["BlockedAreaCodes"],
    }),

    removeBlockedAreaCodes: builder.mutation({
      query: (body) => {
        return {
          url: `areacode-blacklist/areacodes/${body.areacode}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, arg) => {
        return [{ type: "BlockedAreaCodes", id: arg.areacode }];
      },
    }),

    validateBlockedAreaCode: builder.mutation({
      query: (body) => {
        return {
          url: `areacode-blacklist/validate/`,
          method: "POST",
          body: body,
        };
      },
    }),

    loadDarwinGroups: builder.query({
      query: (options = { limit: 5000, offset: 0 }) => {
        return {
          url: `darwin-rank/groups/?limit=${options.limit}&offset=${options.offset}`,
        };
      },

      transformResponse: (response) => {
        return response.records;
      },

      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.map(({ uuid }) => {
                return { type: "DarwinGroup", id: uuid };
              }),
              "DarwinGroup",
            ]
          : ["DarwinGroup"];
      },
    }),

    saveDarwinGroup: builder.mutation({
      query: (body) => {
        return {
          url: `darwin-rank/groups/${
            body.hasOwnProperty("uuid") ? body.uuid : ""
          }`,
          method: body.hasOwnProperty("uuid") ? "PUT" : "POST",
          body,
        };
      },

      invalidatesTags: (result, error, arg) => {
        return [{ type: "DarwinGroup", id: arg.uuid }];
      },
    }),

    loadDarwinGroupMembers: builder.query({
      query: ({ uuid }) => {
        return {
          url: `darwin-rank/placement-group-members/?group_uuid=${uuid}`,
        };
      },

      transformResponse: (response) => {
        return response.records;
      },

      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.map((r) => {
                return { type: "DarwinGroupMember", id: r.placementUUID };
              }),
              "DarwinGroupMember",
            ]
          : ["DarwinGroupMember"];
      },
    }),

    addDarwinGroupMemeber: builder.mutation({
      query: (body) => {
        return {
          url: `darwin-rank/placement-group-members/`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, arg) => {
        return [{ type: "DarwinGroupMember", id: arg.uuid }];
      },
    }),

    deleteDarwinGroupMember: builder.mutation({
      query: ({ placementUUID, groupUUID }) => {
        return {
          url: `darwin-rank/placement-group-members/${placementUUID}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, arg) => {
        return [{ type: "DarwinGroupMember", id: arg.placementUUID }];
      },
    }),

    // Reports Start
    loadAdqPerformanceReport: builder.mutation({
      query: ({ format, slug, ...body }) => {
        // let today = new Date().toLocaleString("en-US", {
        //   year: "numeric",
        //   month: "2-digit",
        //   day: "2-digit",
        // });
        return {
          url:
            format && slug
              ? `reporting/adq-performance/?format=${format}&slug=${slug}`
              : "reporting/adq-performance/",
          method: "POST",
          body: format && slug ? {} : body,
        };
      },
    }),

    loadValidationReport: builder.mutation({
      query: ({ format, slug, limit, ...body }) => {
        return {
          url:
            format && slug
              ? `reporting/single-validation-by-message/?format=${format}&slug=${slug}`
              : `reporting/single-validation-by-message/`,
          method: "POST",
          body: format && slug ? {} : { ...body, groupByDate: true },
        };
      },
    }),

    loadBrokeredLeadViewer: builder.mutation({
      query: ({ format = "json", slug, ...body }) => {
        return {
          url:
            format && slug
              ? `reporting/brokered-lead-viewer/?format=${format}&slug=${slug}`
              : `reporting/brokered-lead-viewer/?format=${format}`,
          method: "POST",
          body: format && slug ? {} : body,
        };
      },
      transformResponse: (response) => {
        return {
          report: response.report.leads.map((lead) => {
            let leadData = {
              status: lead.status,
              email: lead.sessionDetails.session.email,
              sessionUUID: lead.sessionDetails.session.sessionUUID,
              createdAt: lead.sessionDetails.session.createdAt,
              softwareVersion: lead.sessionDetails.session.softwareVersion,
              userData: {
                email: lead.sessionDetails.session.email,
                name: `${lead.sessionDetails.session.firstName} ${lead.sessionDetails.session.lastName}`,
                address1: lead.sessionDetails.session.address1,
                address2: lead.sessionDetails.session.address2,
                city: lead.sessionDetails.session.city,
                state: lead.sessionDetails.session.state,
                zipCode: lead.sessionDetails.session.zipcode,
                dob: lead.sessionDetails.session.DOB,
                gender: lead.sessionDetails.session.gender,
                userIP: lead.sessionDetails.session.userIP,
              },
              device: {
                deviceUserAgent: lead.sessionDetails.session.deviceUserAgent,
                deviceType: lead.sessionDetails.session.deviceType,
                deviceMobileType: lead.sessionDetails.session.deviceMobileType,
                deviceOsName: lead.sessionDetails.session.deviceOsName,
                deviceOsVersion: lead.sessionDetails.session.deviceOsVersion,
                deviceBrowserName:
                  lead.sessionDetails.session.deviceBrowserName,
                deviceBrowserVersion:
                  lead.sessionDetails.session.deviceBrowserVersion,
              },

              requestString: lead.requestString,
              responseString: lead.responseString,
              validationResults: {
                text: !lead.validationResults.length
                  ? ""
                  : lead.validationResults[0].message,
                subtext: !lead.validationResults.length
                  ? ""
                  : lead.validationResults[0].code,
              },
              misc: {
                pubKey: lead.pubKey,
                pubTime: "",
                createdAt: lead.sessionDetails.session.createdAt,
                isNewPdle: lead.isNewPdle ? "true" : "false",
              },
              placement: {
                text: lead.placementName,
                subtext: lead.placementUUID,
              },
              campaign: { text: lead.campaignName, subtext: lead.campaignUUID },
            };
            return leadData;
          }),
          request: response.request,
        };
      },
    }),

    loadBrokeredFailedLeads: builder.mutation({
      query: ({ format, slug, ...body }) => {
        return {
          url:
            format && slug
              ? `reporting/brokered-failed-leads/?format=${format}&slug=${slug}`
              : "reporting/brokered-failed-leads/",
          method: "POST",
          body: format && slug ? {} : body,
        };
      },
      transformResponse: (response) => {
        return {
          placementStats: response.report.placementStats.records.map((lead) => {
            return {
              placement: {
                text: lead.placementName,
                subtext: lead.placementUUID,
              },
              failedAttempted: `${lead.failedLeadCount}/${lead.leadCount}`,
              failedLeadPercentage: lead.failedLeadPercentage,
            };
          }),

          placementDetails: response.report.placementDetails.records.map(
            (lead) => {
              return {
                placement: {
                  text: lead.placementName,
                  subtext: lead.placementUUID,
                },
                errors: lead.errors.map((error) => {
                  return {
                    error: {
                      text: error.errorMessage,
                      subtext: error.errorCode,
                    },
                    id: lead.placementUUID,
                    count: error.errorCount,
                  };
                }),

                failedLeadCount: lead.failedLeadCount,
              };
            }
          ),
          request: response.request,
        };
      },
    }),

    loadSessionViewer: builder.mutation({
      query: ({ format, slug, ...body }) => {
        return {
          url:
            format && slug
              ? `reporting/session-lookup/?format=${format}&slug=${slug}`
              : "reporting/session-lookup/",
          method: "POST",
          body: format && slug ? {} : body,
        };
      },
    }),

    getSessionDetails: builder.mutation({
      query: ({ ...body }) => {
        return {
          url: "reporting/session-details/",
          method: "POST",
          body: body,
        };
      },
      transformResponse: (response) => {
        return {
          session: [response.report.sessionDetailsRecord.session],
          prevalidations: response.report.sessionDetailsRecord.prevalidations
            .length
            ? response.report.sessionDetailsRecord.prevalidations[0]
            : {},

          postvalidations: response.report.sessionDetailsRecord.postvalidations
            .length
            ? response.report.sessionDetailsRecord.postvalidations[0]
            : {},
        };
      },
    }),

    loadTruIntentReport: builder.mutation({
      query: ({ format, slug, ...body }) => {
        return {
          url:
            format && slug
              ? `reporting/tru-intent/?format=${format}&slug=${slug}`
              : "reporting/tru-intent/",
          method: "POST",
          body: format && slug ? {} : body,
        };
      },
      transformResponse: (response) => {
        return {
          placementData: {
            data: response.report.placementSection.records.map((placement) => {
              return {
                placementType: placement.placementType,
                placement: {
                  text: placement.placementName,
                  subtext: placement.placementUUID,
                },
                attemptedLeads: placement.attemptedCount,
                leads: placement.acceptedCount,
                rejectedCount: placement.rejectedCount,
                rejectedPercentage: placement.rejectedPercentage,
                rtRejectedCount: placement.rtRejectedCount,
                rtRejectedPercentage: placement.rtRejectedPercentage,
                estimatedRevenue: placement.estimatedRevenue,
              };
            }),
            totals: {
              attemptedLeads:
                response.report.placementSection.totals.attemptedCount,
              leads: response.report.placementSection.totals.acceptedCount,
              rejectedCount:
                response.report.placementSection.totals.rejectedCount,
              rejectedPercentage:
                response.report.placementSection.totals.rejectedPercentage,
              rtRejectedCount:
                response.report.placementSection.totals.rtRejectedCount,
              rtRejectedPercentage:
                response.report.placementSection.totals.rtRejectedPercentage,
              estimatedRevenue:
                response.report.placementSection.totals.estimatedRevenue,
            },
          },
          campaignData: {
            data: response.report.campaignSection.records.map((campaign) => {
              return {
                campaign: {
                  text: campaign.campaignName,
                  subtext: campaign.campaignUUID,
                },
                campaignCurrentRate: campaign.campaignCurrentRate,
                campaignRateList: campaign.campaignRateList,
                attemptedLeads: campaign.attemptedCount,
                leads: campaign.acceptedCount,
                rejectedCount: campaign.rejectedCount,
                rejectedPercentage: campaign.rejectedPercentage,
                rtRejectedCount: campaign.rtRejectedCount,
                rtRejectedPercentage: campaign.rtRejectedPercentage,
                estimatedRevenue: campaign.estimatedRevenue,
              };
            }),
            totals: {
              attemptedLeads:
                response.report.campaignSection.totals.attemptedCount,
              leads: response.report.campaignSection.totals.acceptedCount,
              rejectedCount:
                response.report.campaignSection.totals.rejectedCount,
              rejectedPercentage:
                response.report.campaignSection.totals.rejectedPercentage,
              rtRejectedCount:
                response.report.campaignSection.totals.rtRejectedCount,
              rtRejectedPercentage:
                response.report.campaignSection.totals.rtRejectedPercentage,
              estimatedRevenue:
                response.report.campaignSection.totals.estimatedRevenue,
            },
          },
          request: response.request,
        };
      },
    }),

    loadTruIntentFailedLeads: builder.mutation({
      query: ({ format, slug, ...body }) => {
        return {
          url:
            format && slug
              ? `reporting/tru-intent-rejected-leads/?format=${format}&slug=${slug}`
              : "reporting/tru-intent-rejected-leads/",
          method: "POST",
          body: format && slug ? {} : body,
        };
      },
      transformResponse: (response) => {
        return {
          leadRejections: response.report.rejectionsSection.records.map(
            (rejection) => {
              return {
                campaign: {
                  text: `${rejection.campaignShortCode} - ${rejection.campaignName}`,
                  subtext: rejection.campaignUUID,
                },
                placement: {
                  text: `${rejection.placementType} -${rejection.placementLegacyCode} - ${rejection.placementName}`,
                  subtext: rejection.placementUUID,
                },
                attempts: rejection.attempts.map((attempt) => {
                  return {
                    createdAt: attempt.createdAt,
                    optinUUID: attempt.optinUUID,
                    sessionData: [
                      {
                        sessionUUID: attempt.sessionDetails.session.sessionUUID,
                        createdAt: attempt.sessionDetails.session.createdAt,
                        softwareVersion:
                          attempt.sessionDetails.session.softwareVersion,
                        userData: {
                          multiple: true,
                          data: {
                            email: attempt.sessionDetails.session.email,
                            name: `${attempt.sessionDetails.session.firstName} ${attempt.sessionDetails.session.lastName}`,
                            address1: attempt.sessionDetails.session.address1,
                            address2: attempt.sessionDetails.session.address2,
                            city: attempt.sessionDetails.session.city,
                            state: attempt.sessionDetails.session.state,
                            zipCode: attempt.sessionDetails.session.zipcode,
                            dob: attempt.sessionDetails.session.DOB,
                            gender: attempt.sessionDetails.session.gender,
                            userIP: attempt.sessionDetails.session.userIP,
                          },
                        },
                        device: {
                          multiple: true,
                          data: {
                            // move bulk to api slice
                            deviceUserAgent:
                              attempt.sessionDetails.session.deviceUserAgent,
                            deviceType:
                              attempt.sessionDetails.session.deviceType,
                            deviceMobileType:
                              attempt.sessionDetails.session.deviceMobileType,
                            deviceOsName:
                              attempt.sessionDetails.session.deviceOsName,
                            deviceOsVersion:
                              attempt.sessionDetails.session.deviceOsVersion,
                            deviceBrowserName:
                              attempt.sessionDetails.session.deviceBrowserName,
                            deviceBrowserVersion:
                              attempt.sessionDetails.session
                                .deviceBrowserVersion,
                          },
                        },
                      },
                    ],
                    validations: {
                      multiple: true,
                      data: attempt.validations
                        .map((v, idx) =>
                          v.message
                            ? {
                                [v.serviceName]: v.message,
                              }
                            : {}
                        )
                        .reduce((acc, val) => ({ ...acc, ...val }), {}),
                    },
                  };
                }),
                attemptsCount: rejection.attempts.length,
                validationsSummary: {
                  multiple: true,
                  data: rejection.attempts
                    .flatMap((attempt) =>
                      attempt.validations.map((v, idx) =>
                        v.message
                          ? {
                              [v.serviceName]: v.message,
                            }
                          : {}
                      )
                    )
                    .reduce((acc, val) => ({ ...acc, ...val }), {}),
                },
              };
            }
          ),
          titleData: {
            title:
              response?.request?.placementUUIDs.length > 0
                ? `Placement: ${response?.report.rejectionsSection.records[0]?.placementName} (${response?.request?.placementUUIDs[0]})`
                : response?.request?.campaignUUIDs.length > 0
                ? `Campaign: ${response?.report.rejectionsSection.records[0]?.campaignName} (${response?.request?.campaignUUIDs[0]})`
                : "N/A",
            startDate: response.request.startDate,
            endDate: response.request.endDate,
            customDataFilter: response.request.customDataFilter || "None",
          },
          request: response.request,
        };
      },
    }),

    loadTruIntentFailedRts: builder.mutation({
      query: ({ format, slug, ...body }) => {
        return {
          url:
            format && slug
              ? `reporting/tru-intent-rejected-rts/?format=${format}&slug=${slug}`
              : "reporting/tru-intent-rejected-rts/",
          method: "POST",
          body: format && slug ? {} : body,
        };
      },
      transformResponse: (response) => {
        return {
          rtsRejections: response.report.rtsSection.records.map((rejection) => {
            return {
              lead: rejection.leadUUID,
              campaign: {
                text: `${rejection.campaignShortCode} - ${rejection.placementLegacyCode} - ${rejection.campaignName}`,
                subtext: rejection.campaignUUID,
              },
              placement: {
                text: `${rejection.placementType} - ${rejection.placementName}`,
                subtext: rejection.placementUUID,
              },
              attemptsCount: rejection.attempts.length,
              attempts: rejection.attempts.map((attempt) => {
                return {
                  createdAt: attempt.createdAt,
                  optinUUID: attempt.optinUUID,
                  sessionData: [
                    {
                      sessionUUID: attempt.sessionDetails.session.sessionUUID,
                      createdAt: attempt.sessionDetails.session.createdAt,
                      softwareVersion:
                        attempt.sessionDetails.session.softwareVersion,
                      userData: {
                        multiple: true,
                        data: {
                          email: attempt.sessionDetails.session.email,
                          name: `${attempt.sessionDetails.session.firstName} ${attempt.sessionDetails.session.lastName}`,
                          address1: attempt.sessionDetails.session.address1,
                          address2: attempt.sessionDetails.session.address2,
                          city: attempt.sessionDetails.session.city,
                          state: attempt.sessionDetails.session.state,
                          zipCode: attempt.sessionDetails.session.zipcode,
                          dob: attempt.sessionDetails.session.DOB,
                          gender: attempt.sessionDetails.session.gender,
                          userIP: attempt.sessionDetails.session.userIP,
                        },
                      },
                      device: {
                        multiple: true,
                        data: {
                          // move bulk to api slice
                          deviceUserAgent:
                            attempt.sessionDetails.session.deviceUserAgent,
                          deviceType: attempt.sessionDetails.session.deviceType,
                          deviceMobileType:
                            attempt.sessionDetails.session.deviceMobileType,
                          deviceOsName:
                            attempt.sessionDetails.session.deviceOsName,
                          deviceOsVersion:
                            attempt.sessionDetails.session.deviceOsVersion,
                          deviceBrowserName:
                            attempt.sessionDetails.session.deviceBrowserName,
                          deviceBrowserVersion:
                            attempt.sessionDetails.session.deviceBrowserVersion,
                        },
                      },
                    },
                  ],
                  validationCode: attempt.validationCode,
                };
              }),
              validationCode: rejection.validationCode,
            };
          }),
          titleData: {
            title:
              response?.request?.placementUUIDs.length > 0
                ? `Placement: ${response?.report.rtsSection.records[0]?.placementName} (${response?.request?.placementUUIDs[0]})`
                : response?.request?.campaignUUIDs.length > 0
                ? `Campaign: ${response?.report.rtsSection.records[0]?.campaignName} (${response?.request?.campaignUUIDs[0]})`
                : "N/A",
            startDate: response.request.startDate,
            endDate: response.request.endDate,
            customDataFilter: response.request.customDataFilter || "None",
          },
          request: response.request,
        };
      },
    }),

    getPubPrevalidationByDate: builder.mutation({
      query: ({ format, slug, limit, ...body }) => {
        return {
          url:
            format && slug
              ? `reporting/pub-prevalidation-by-date/?format=${format}&slug=${slug}`
              : "reporting/pub-prevalidation-by-date/",
          method: "POST",
          body: format && slug ? {} : body,
        };
      },
      transformResponse: (response) => {
        // Re-structuring dynamic column values for easier access:
        const dynamicTableRows =
          // Using pivot dimension date values:
          response.Report.Components[0].PivotSettings.PivotDimensionValues.reduce(
            (accumulator, value, idx) => {
              return {
                ...accumulator,
                [idx]: response.Report.Components[0].RowCluster.map((row) => {
                  // Return slices of every 3 elements
                  // (they're all dumped in the same array, should probably place every 3 in a new index of RowValueSets)
                  return {
                    date: value,
                    placement: {
                      text: row.RowKeys[0].DisplayKey,
                      subtext: row.RowKeys[1].DisplayKey,
                    },
                    campaign: {
                      text: row.RowKeys[2].DisplayKey,
                      subtext: row.RowKeys[4].DisplayKey,
                    },

                    values: row.RowValueSets[0].RowValues.slice(
                      idx * 3,
                      idx * 3 + 3
                    ),
                  };
                }),
              };
            },
            {}
          );

        return {
          report: {
            data: response.Report.Components[0].RowCluster.map((row, idx) => {
              return {
                placement: row.RowKeys[0].DisplayKey || "",
                campaign: row.RowKeys[2].DisplayKey || "",
                shortCode: row.RowKeys[3].DisplayKey || "",
                dynamicRow: Object.entries(dynamicTableRows).map((column) => {
                  return Object.assign({}, column[1][idx]);
                }),
              };
            }),
            dynamicRows: Object.values(dynamicTableRows).flat(),
          },
          request: response.Request,
        };
      },
    }),

    getPubPrevalidationByMessage: builder.mutation({
      query: ({ format, slug, limit, ...body }) => {
        return {
          url:
            format && slug
              ? `reporting/pub-prevalidation-by-message/?format=${format}&slug=${slug}`
              : "reporting/pub-prevalidation-by-message/",
          method: "POST",
          body: format && slug ? {} : body,
        };
      },
      transformResponse: (response) => {
        return {
          report: {
            data: response.Report.Components[0].RowCluster.map((row, idx) => {
              return {
                placement: row.RowKeys[0].DisplayKey || "",
                campaign: row.RowKeys[2].DisplayKey || "",
                shortCode: row.RowKeys[3].DisplayKey || "",
                leadAlreadyExists: row.RowValueSets[0].RowValues[0].DataValue,
                leadExistsInClientSystem:
                  row.RowValueSets[0].RowValues[1].DataValue,
              };
            }),
          },
          request: response.Request,
        };
      },
    }),

    loadActivityLog: builder.mutation({
      query: ({ format, slug, limit, ...body }) => {
        return {
          url:
            format && slug
              ? `reporting/activity-log/?format=${format}&slug=${slug}`
              : "reporting/activity-log/?format=json",
          method: "POST",
          body: format ? {} : body,
        };
      },
    }),

    loadCampaignQuickStatus: builder.mutation({
      query: ({ format, slug, ...body }) => {
        return {
          url:
            format && slug
              ? `reporting/campaign-quick-status/?format=${format}&slug=${slug}`
              : "reporting/campaign-quick-status/",
          method: "POST",
          body: body,
        };
      },
    }),

    loadInternalBudget: builder.mutation({
      query: ({ format, slug, ...body }) => {
        return {
          url:
            format && slug
              ? `reporting/internal-budget/?format=${format}&slug=${slug}`
              : "reporting/internal-budget/",
          method: "POST",
          body: body,
        };
      },
    }),

    loadBuySheet: builder.mutation({
      query: ({ format, slug, ...body }) => {
        return {
          url:
            format && slug
              ? `reporting/buy-sheet/?format=${format}&slug=${slug}`
              : "reporting/buy-sheet/",
          method: "POST",
          body: body,
        };
      },

      transformResponse: (response) => {
        return {
          report: response.Report.Associations,
          request: response.Request,
        };
      },

      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.report.map((asoc) => {
                return {
                  //   type: "BuySheetAssoc",
                  //   id: v.service_name,
                };
              }),
              "BuySheetAssoc",
            ]
          : ["BuySheetAssoc"];
      },
    }),

    loadDeliverableViewer: builder.query({
      query: ({ limit, ...body }) => {
        return {
          url: `deliverable-manager/deliverable-viewer/?limit=${limit}&campaign_uuid=${body.campaignUUIDs}&placement_uuid=${body.placementUUIDs}&deliverable_status=${body.deliverableStatus}&start_date=${body.startDate}&end_date=${body.endDate}`,
        };
      },

      transformResponse: (response) => {
        return {
          records: response.data.records,
          campaignAggregates: response.campaignAggregates.records,
        };
      },

      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.records.map((del) => {
                return {
                  type: "Deliverable",
                  id: del.deliverable.UUID,
                };
              }),
              "Deliverable",
            ]
          : ["Deliverable"];
      },
    }),

    updateDeliverableViewerStatus: builder.mutation({
      query: ({ ...body }) => {
        return {
          url: "deliverable-manager/deliverables/update-status/",
          method: "PUT",
          body: body,
        };
      },

      invalidatesTags: (result, error, arg) => {
        return [{ type: "Deliverable", id: arg.deliverableUUID }];
      },
    }),

    loadQuarantine: builder.query({
      query: (body) => {
        return {
          url: `optin-reviewer/reviewables/report`,
          method: "POST",
          body: body,
        };
      },

      providesTags: (result, error, arg) => {
        return ["Reviewable"];
      },
    }),

    // Reports Start
    loadQuestionAnswerBreakdownReport: builder.mutation({
      query: ({ format, slug, ...body }) => {
        // let today = new Date().toLocaleString("en-US", {
        //   year: "numeric",
        //   month: "2-digit",
        //   day: "2-digit",
        // });
        return {
          url:
            format && slug
              ? `reporting/question-answer-breakdown/?format=${format}&slug=${slug}`
              : "reporting/question-answer-breakdown/",
          method: "POST",
          body: format && slug ? {} : body,
        };
      },
      // transformResponse: (response) => {
      //   return {
      //     records: response.re,
      //   };
      // },
    }),

    updateBulkReviewStatus: builder.mutation({
      query: ({ ...body }) => {
        return {
          url: `optin-reviewer/reviewables/bulkReviewStatusUpdate`,
          method: "PUT",
          body: body,
        };
      },

      invalidatesTags: (result, error, arg) => {
        return ["Reviewable"];
      },
    }),
  }),
});

// Export query hooks.
export const {
  // Campaigns
  useGetCampaignsQuery,
  useGetCampaignByIdQuery,
  useUpdateCampaignByIdMutation,
  useSearchCampaignsMutation,
  useGetCustomersQuery,
  useUpdateCustomersMutation,
  useGetSalesPersonQuery,
  useGetValidatorsQuery,
  useGetDefaultValidatorsQuery,
  useGetCampaignValidationDataMutation,
  useGetCampaignCustomValidationQuery,
  useTestCustomValidationMutation,
  useSaveCustomValidationMutation,
  useGetCampaignLeadDeliveryQuery,
  useSaveCampaignLeadDeliveryMutation,
  useGetLatestDeliveriesQuery,
  useCreateDeliveryFromQueryMutation,
  useDeleteLeadDeliveryConfigMutation,
  useCloneLeadDeliveryConfigMutation,
  useTestDeliveryMutation,
  useGetCustomFieldsQuery,
  useAddDefaultValidatorMutation,
  useRemoveDefaultValidatorMutation,
  useDisableValidatorMutation,
  useGetEmailValidatorConfigurationQuery,
  useGetDefaultPhoneConfigurationQuery,
  useGetPhoneValidatorConfigurationQuery,
  useGetDefaultAddressConfigurationQuery,
  useGetAddressValidatorConfigurationQuery,
  useGetCustomValidatorConfigurationQuery,
  useUpdatePhoneValidatorConfigurationMutation,
  useUpdateEmailValidatorConfigurationMutation,
  useUpdateAddressValidatorConfigurationMutation,
  useGetCategoriesByIdQuery,
  useGetAllCategoriesQuery,
  useUpdateCategoriesByIdMutation,
  useGetCampaignCriteriaQuery,
  useUpdateCampaignCriteriaMutation,
  usePreviewPrePingMutation,
  useTestPrePingMutation,
  useGetCampaignQuestionsQuery,
  useUpdateCampaignQuestionMutation,
  useGetStatsQuery,
  useLazyGetStatsQuery,
  useRemoveCampaignQuestionMutation,
  useReorderCampaignQuestionsMutation,
  useAddCampaignQuestionMutation,
  useGetCampaignCreativeQuery,
  useGetCampaignCreativePreviewMutation,
  useUpdateCreativeMutation,
  useNewCreativeMutation,
  useGetCreativeCriteriaQuery,
  useUpdateCreativeCriteriaMutation,
  useRemoveCreativeMutation,
  useGetCampaignGroupsQuery,
  useUpdateCampaignGroupingMutation,
  useRemoveCampaignFromGroupMutation,
  useGetCampaignAssociationsQuery,
  useUpdateCampaignAssociationsMutation,

  // Placements
  useGetPlacementsQuery,
  useGetPlacementByIdQuery,
  useUpdatePlacementByIdMutation,
  useNewPlacementMutation,
  useGetRankersQuery,
  useGetLeadAcceptanceQuery,
  useUpdateLeadAcceptanceMutation,
  useGetPlacementAddressValidationQuery,
  useUpdatePlacementAddressValidationMutation,
  useGetPlacementCriteriaQuery,
  useUpdatePlacementCriteriaMutation,
  useRemovePlacementCriteriaMutation,
  useGetPlacementGroupsQuery,
  useUpdatePlacementGroupingMutation,
  useRemovePlacementFromGroupMutation,

  //Ops
  useUploadEmailSuppressionMutation,
  useSearchZipCodesMutation,
  useGetBadWordsQuery,
  useAddBadWordMutation,
  useTestBadWordQuery,
  useRemoveBadWordMutation,
  useGetIpLookupMutation,
  useGetAllBlockedEmailDomainsQuery,
  useGetBlockedEmailsQuery,
  useGetEmailDomainsToReviewQuery,
  useSetEmailDomainAsReviewedMutation,
  useUpdateBlockedEmailDomainMutation,
  useUpdateBulkBlockedEmailDomainMutation,
  useRemoveBlockedEmailDomainMutation,
  useUpdateBulkBlockedEmailsMutation,
  useValidateBlockedEmailsMutation,
  useRemoveBlockedEmailMutation,
  useGetBlockedAreaCodesQuery,
  useUpdateBulkBlockedAreaCodesMutation,
  useRemoveBlockedAreaCodesMutation,
  useValidateBlockedAreaCodeMutation,
  useAddKeyCloakAccountMutation,
  useGetContactNotificationConfigurationsQuery,
  useSaveContactNotificationConfigurationMutation,
  useDeleteContactNotificationConfigurationMutation,
  useLoadDarwinGroupsQuery,
  useLoadDarwinGroupMembersQuery,
  useSaveDarwinGroupMutation,
  useAddDarwinGroupMemeberMutation,
  useDeleteDarwinGroupMemberMutation,

  // Reports
  useLoadAdqPerformanceReportMutation,
  useLoadValidationReportMutation,
  useLoadBrokeredLeadViewerMutation,
  useLoadBrokeredFailedLeadsMutation,
  useLoadTruIntentReportMutation,
  useLoadTruIntentFailedLeadsMutation,
  useLoadTruIntentFailedRtsMutation,
  useLoadSessionViewerMutation,
  useGetSessionDetailsMutation,
  useGetPubPrevalidationByDateMutation,
  useGetPubPrevalidationByMessageMutation,
  useLoadActivityLogMutation,
  useLoadCampaignQuickStatusMutation,
  useLoadInternalBudgetMutation,
  useLoadBuySheetMutation,
  useLoadDeliverableViewerQuery,
  useUpdateDeliverableViewerStatusMutation,
  useLoadQuarantineQuery,
  useLoadQuestionAnswerBreakdownReportMutation,
  useUpdateBulkReviewStatusMutation,
} = adquireApi;
